<script setup lang="ts">
import type { ContentBlock } from '~/types/api'
import { InternalRouteName } from '~/constants/internal-route-name'

definePageMeta({
    layout: false,
    name: InternalRouteName.HOME,
})

const heroBlocks = computed(() => {
    return useContentBlocksState().value?.filter((block: ContentBlock) => block.type === 'hero_header')
})

const othersBlocks = computed(() => {
    return useContentBlocksState().value?.filter((block: ContentBlock) => block.type !== 'hero_header')
})

const heroTitle = computed(() => heroBlocks.value?.[0]?.title)
const hasHeroImg = computed(() => heroBlocks.value?.[0]?.image?.processable)
const { metaClient, metaServer } = usePageSeoMeta()

useSeoMeta(metaClient)
useServerSeoMeta(metaServer)
useCacheControl({
    maxAge: 60 * 5, // 5 minutes
    sMaxAge: 60 * 10, // 10 minutes
    staleWhileRevalidate: 60 * 10,
    public: true,
})
</script>

<template>
    <NuxtLayout name="default" with-reservation-form :offset-reservation-form="hasHeroImg">
        <template #header>
            <VContentBlockFactory :content-blocks="heroBlocks" />
        </template>
        <template #default>
            <VWebPageStructuredData :title="heroTitle" />
            <main class="grid-item-main" :class="$style.main">
                <VContentBlockFactory :content-blocks="othersBlocks" />
            </main>
        </template>
    </NuxtLayout>
</template>

<style lang="scss" module>
.main {
    display: flex;
    flex-direction: column;
}
</style>
